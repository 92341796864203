import { InputText } from './text'
import { InputPassword } from './password'

type InputType = typeof InputText & {
  Text: typeof InputText
  Password: typeof InputPassword
}

export const Input = InputText as InputType

Input.Text = InputText
Input.Password = InputPassword
