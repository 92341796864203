import { api, getAccessToken } from 'utils'
import { PaginationData } from 'types'

import { GetRedeemVouchersParams, RedeemVoucher, ValidateRedeemVoucherDto } from '.'
import { CLIENT_CODE } from 'constant'

export const fetchRedeemVouchersUsed = async (
  params: GetRedeemVouchersParams
): Promise<PaginationData<RedeemVoucher>> => {
  const accessToken = await getAccessToken()
  try {
    const res = await api.get<PaginationData<RedeemVoucher>>(
      '/customer/vouchers',
      {
        params: {
          access_token: accessToken,
          limit: params.paginator.limit,
          page: params.paginator.page,
          status: params.status,
          code_client: CLIENT_CODE
        }
      }
    )
    const { data } = res
    return data
  } catch (e) {
    throw e
  }
}

export const fetchRedeemVouchersUnused = async (
  params: GetRedeemVouchersParams
): Promise<PaginationData<RedeemVoucher>> => {
  const accessToken = await getAccessToken()
  try {
    const res = await api.get<PaginationData<RedeemVoucher>>(
      '/customer/vouchers',
      {
        params: {
          access_token: accessToken,
          limit: params.paginator.limit,
          page: params.paginator.page,
          status: params.status,
          code_client: CLIENT_CODE
        }
      }
    )
    const { data } = res
    return data
  } catch (e) {
    throw e
  }
}

export const validateRedeemVoucher = async (
  dto: ValidateRedeemVoucherDto
): Promise<any> => {
  try {
    const body = {
      client_code: CLIENT_CODE,
      ...dto
    }
    const res = await api.post(
      '/redeem/voucher/validate',
      body
    )
    const { data } = res
    return data
  } catch (e) {
    throw e
  }
}
