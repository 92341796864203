import { CLIENT_CODE } from 'constant'
import { useState, useCallback, useEffect } from 'react'
import { FetchStatus } from 'types'
import { api, getAccessToken } from 'utils'

export const useLuckyNumber = () => {
  const [data, setData] = useState<string[]>([])
  const [status, setStatus] = useState<FetchStatus>(FetchStatus.IDLE)

  const fetchLuckyNumber = useCallback(async () => {
    try {
      setStatus(FetchStatus.LOADING)
      const accessToken = await getAccessToken()
      const res = await api.get(
        `/customer/lucky-draw?page=1&limit=100&code_client=${CLIENT_CODE}&access_token=${accessToken}`,
      )
      const { data } = res.data
      setData(data)
      setStatus(FetchStatus.LOADED)
    } catch (e) {
      setStatus(FetchStatus.ERROR)
    }
  }, [])

  useEffect(() => {
    fetchLuckyNumber()
  }, [])

  return {
    data,
    isLoading: status === FetchStatus.LOADING,
    isError: status === FetchStatus.ERROR,
    refetch: fetchLuckyNumber,
  }
}
