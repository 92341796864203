import { api } from 'utils'
import { CLIENT_CODE } from 'constant'

import {
  GetAllPromoResponse,
  GetAllPromoParams,
  PromoService,
  GetPromoDetailResponse
} from '.'

const getAllPromo = async (
  params: GetAllPromoParams,
): Promise<GetAllPromoResponse> => {
  try {
    const res = await api.get<GetAllPromoResponse>('/promotion', { params })
    return res.data
  } catch (e) {
    throw e
  }
}

const getPromoDetail = async (
  id: string,
): Promise<GetPromoDetailResponse> => {
  try {
    const res = await api.get<GetPromoDetailResponse>(`/promotion/${id}?client_code=${CLIENT_CODE}`)
    return res.data
  } catch (e) {
    throw e
  }
}

export const promoService: PromoService = {
  getAllPromo,
  getPromoDetail
}
