import { FC, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Scrollbar } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/scrollbar'

import { Icons } from 'assets'
import { useStoreActions, useStoreState } from 'stores'
import { CLIENT_CODE } from 'constant'
import { formatCurrency, isEmpty } from 'utils'

import './styles.scss'
import { Alert, Button, Input, Loader, Topbar } from 'components'
import { FetchStatus, FormStatus } from 'types'
import { useFormik } from 'formik'
import { useChangePassword } from './hooks'

export const ChangePassword: FC = () => {
  const navigate = useNavigate()

  const [oldPassword, setOldPassword] = useState<string>('')
  const [newPassword, setNewPassword] = useState<string>('')
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>('')
  const [error, setError] = useState<string>('')
  const { status, message, submit } = useChangePassword({
    onError: (message: string) => setError(message)
  })

  const reset = () => {
    if (newPassword !== confirmNewPassword) setError('Passwords not match')
    else submit({ old_password: oldPassword, new_password: newPassword })
  }

  return (
    <div className="edit-profile-container">
      <Topbar
        title="Edit Profile"
        onBack={() => navigate(-1)}
      />
      <div style={{ padding: '1rem' }}>
        <Input.Text
          name="old_password"
          placeholder="Old password"
          value={oldPassword}
          onChange={e => setOldPassword(e.target.value)}
        />
        <Input.Text
          name="new_password"
          placeholder="New password"
          value={newPassword}
          onChange={e => setNewPassword(e.target.value)}
        />
        <Input.Text
          name="cofirm_new_password"
          placeholder="Confirm New password"
          value={confirmNewPassword}
          onChange={e => setConfirmNewPassword(e.target.value)}
        />
        <Button
          label="Save"
          onClick={reset}
          isLoading={status === FormStatus.LOADING}
        />
      </div>
      {!isEmpty(error) &&
        <Alert
          title="Change Password Failed"
          message={error}
          onClick={() => setError('')}
        />
      }
      {!isEmpty(message) &&
        <Alert
          title="Change Password Success"
          message={message}
          onClick={() => navigate(-1)}
        />
      }
    </div>
  )
}
