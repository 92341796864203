import { createStore, createTypedHooks, EasyPeasyConfig } from 'easy-peasy'

import { AuthModel, auth } from './auth'
import { ProfileModel, profile } from './profile'
import { PromoModel, promo } from './promo'
import { RedeemModel, redeem } from './redeem'
import { HistoryModel, history } from './history'
import { OutletModel, outlet } from './outlet'
import { EventModel, event } from './event'
import { NotificationModel, notification } from './notification'
import { KeepingBottleModel, keepingbottle } from './keeping-bottle'

export * from './auth'
export * from './profile'
export * from './promo'
export * from './history'
export * from './outlet'
export * from './event'
export * from './keeping-bottle'

export interface StoreModel {
  auth: AuthModel,
  profile: ProfileModel,
  promo: PromoModel,
  redeem: RedeemModel,
  history: HistoryModel,
  outlet: OutletModel,
  event: EventModel,
  notification: NotificationModel
  keepingbottle: KeepingBottleModel
}

const storeModel: StoreModel = {
  auth,
  profile,
  promo,
  redeem,
  history,
  outlet,
  event,
  notification,
  keepingbottle
}

const storeConfig: EasyPeasyConfig = {
  devTools: true
}

const store = createStore(storeModel, storeConfig)
const typedHooks = createTypedHooks<StoreModel>()

export const { useStoreActions, useStoreDispatch, useStoreState } = typedHooks
export default store
