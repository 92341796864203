import { CLIENT_CODE } from 'constant'
import { api, getAccessToken } from 'utils'

import { InternalRedeemDto, UltraVoucherRedeemDto, ExternalRedeemDto } from '.'

const submitRedeemProduct = async (dto: InternalRedeemDto | UltraVoucherRedeemDto | ExternalRedeemDto): Promise<any> => {
  const accessToken = await getAccessToken()
  try {
    const body = {
      access_token: accessToken,
      code_client: CLIENT_CODE,
      ...dto
    }

    let URL = 'redeem/voucher'

    const res = await api.post(URL, body)
    const { data } = res
    return data
  } catch (e) {
    throw e
  }
}

export const redeemProductService = {
  submitRedeemProduct
}
