import { action, thunk, thunkOn } from 'easy-peasy'

import { AuthStatus, FetchStatus } from 'types'
import { generateTokenBottle, isEmpty } from 'utils'

import { CreateTokenBottle, Profile, ProfileModel, ProfileService } from '.'

export const profile: ProfileModel = {
  profile: {} as Profile,
  status: FetchStatus.LOADING,
  error: {},

  // Actions
  setProfile: action((state, payload) => {
    if (isEmpty(state.profile)) {
      console.debug('Sending one signal tag', payload?.email)
    }
    state.profile = payload
  }),
  setStatus: action((state, payload) => {
    state.status = payload
  }),
  setError: action((state, payload) => {
    state.error = payload
  }),
  clearState: action((state) => {
    state.profile = {} as Profile
    state.status = FetchStatus.LOADING
    state.error = {}
  }),

  // Thunks
  fetchProfile: thunk(async (action) => {
    try {
      action.setStatus(FetchStatus.LOADING)
      const res = await ProfileService.fetchProfile()
      action.setProfile(res.data)
      action.setStatus(FetchStatus.LOADED)
      action.generateTokenBottle(res.data)
    } catch (e) {
      action.clearState()
      action.setError(e)
      action.setStatus(FetchStatus.ERROR)
    } finally {
      action.setError({})
    }
  }),
  generateTokenBottle: thunk(async (action,payload) => {
    const params:CreateTokenBottle = {
      sub: payload?.phone.mobile,
      user: {
        id: payload.id,
        code: payload.code,
        barcode: payload.barcode,
        default_phone: payload.phone.mobile,
        name: payload.name,
        id_number: payload.id_number,
        profile_picture: payload.profile_picture || "",
        active: payload.active,
        total_expense: payload.total_expense,
        point: {
          redeem: payload.point.redeem,
          lucky_draw: payload.point.lucky_draw,
        },
        merchant_information: {
          merchant_group_id: 1,
        },
      },
    }
    
    await generateTokenBottle(params)
  }),
  refreshProfile: thunk(async (action) => {
    try {
      action.setStatus(FetchStatus.REFRESHING)
      const res = await ProfileService.fetchProfile()
      action.setProfile(res.data)
    } catch (e) {
      action.clearState()
      action.setError(e)
      action.setStatus(FetchStatus.ERROR)
    } finally {
      action.setError({})
    }
  }),

  // Thunks On
  onLoggedOut: thunkOn(
    (_, storeAction) => storeAction.auth.setAuthStatus,
    (action, target) => {
      if (target.payload === AuthStatus.UNAUTHENTICATED) {
        action.clearState()
      }
    }
  )
}