import { FC, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Scrollbar } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/scrollbar'

import { Icons } from 'assets'
import { useStoreActions, useStoreState } from 'stores'
import { CLIENT_CODE } from 'constant'
import { formatCurrency } from 'utils'

import './styles.scss'
import { Button, Input, Loader, Topbar } from 'components'
import { FetchStatus } from 'types'
import { useFormik } from 'formik'
import { useEditProfile } from './hooks'

export const EditProfile: FC = () => {
  const navigate = useNavigate()

  const { isAuthenticated } = useStoreState((state) => state.auth)
  const { fetchProfile } = useStoreActions(action => action.profile)
  const { profile, status: statusProfile } = useStoreState(state => state.profile)

  const [name, setName] = useState<string>(profile?.name || '')
  const [phone, setPhone] = useState<string>(`+${profile?.phone?.mobile}` || '')
  const [email, setEmail] = useState<string>(profile?.email || '')
  const [birthDate, setBirthDate] = useState<any>(profile?.birth_date || '')
  const [gender, setGender] = useState<any>(profile?.gender || '')
  const [religion, setReligion] = useState<any>(profile?.religion || '')
  const [maritalStatus, setMaritalStatus] = useState<any>(profile?.marital_status || '')
  const [province, setProvince] = useState(profile?.address?.province ? profile?.address?.province.id.toString() : '')
  const [city, setCity] = useState(profile?.address?.city ? profile?.address?.city.id.toString() : '')
  const [address, setAddress] = useState(profile?.address?.address ? profile?.address?.address : '')

  useEffect(() => {
    if (isAuthenticated) fetchProfile()
  }, [isAuthenticated, fetchProfile])

  useEffect(() => {
    fetchProfile()
  }, [])

  const loading = statusProfile === FetchStatus.LOADING

  const { isLoading, error, success, submit } = useEditProfile()

  return (
    <div className="edit-profile-container">
      <Topbar
        title="Edit Profile"
        onBack={() => navigate(-1)}
      />
      <div style={{ padding: '1rem' }}>
        <div className="label">Name</div>
        <Input.Text
          name="name"
          placeholder="Enter your name"
          value={name}
          onChange={e => setName(e.target.value)}
        />
        <div className="label">Mobile Number</div>
        <Input.Text
          name="mobile"
          placeholder="Enter your mobile number"
          value={phone}
          onChange={e => setPhone(e.target.value)}
          disabled
        />
        <div className="label">Email</div>
        <Input.Text
          name="email"
          placeholder="Enter your email address"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <div className="label">Address</div>
        <Input.Text
          name="address"
          placeholder="Enter your address"
          value={address}
          onChange={e => setAddress(e.target.value)}
        />
        <Button
          label="Save"
          onClick={() => {
            // @ts-ignore
            submit({
              name,
              email,
              address
            })
          }}
          isLoading={isLoading}
        />
      </div>
    </div>
  )
}
