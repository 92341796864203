import { useEffect } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";

import { Container } from "components";
import {
  Splash,
  Login,
  Home,
  Promotion,
  Catalogue,
  CatalogueDetail,
  History,
  Store,
  Event,
  PromotionDetail,
  Notification,
  NotificationDetail,
  Reward,
  RewardBenefit,
  Profile,
  EditProfile,
  ChangePassword,
  ConvertPoint,
  PointLuckyNumber,
  EventDetail,
  KeepingBottle,
  KeepingBottleDetail,
  Register
} from "pages";
import { useStoreActions, useStoreState } from "stores";
import { AuthStatus } from "types";

export const App = () => {
  const { authStatus, isAuthenticated } = useStoreState((state) => state.auth);
  const { checkAuth } = useStoreActions((action) => action.auth);

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  const PrivateRoutes = () => {
    if (!isAuthenticated && authStatus !== AuthStatus.LOADING)
      return <Navigate to="/login" replace />;
    return <Outlet />;
  };

  return (
    <Container>
      <Routes>
        <Route path="/" element={<Splash />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route element={<PrivateRoutes />}>
          <Route path="/home" element={<Home />} />
          <Route path="/reward/benefit" element={<RewardBenefit />} />
          <Route path="/promotion" element={<Promotion />} />
          <Route path="/promotion/:id" element={<PromotionDetail />} />
          <Route path="/catalogue" element={<Catalogue />} />
          <Route path="/reward" element={<Reward />} />
          <Route path="/catalogue/:id" element={<CatalogueDetail />} />
          <Route path="/history" element={<History />} />
          <Route path="/store" element={<Store />} />
          <Route path="/event" element={<Event />} />
          <Route path="/event/:id" element={<EventDetail />} />
          <Route path="/notification" element={<Notification />} />
          <Route path="/notification/:id" element={<NotificationDetail />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/profile/edit" element={<EditProfile />} />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="/convert-point" element={<ConvertPoint />} />
          <Route path="/point-lucky-number" element={<PointLuckyNumber />} />
          <Route path="/keeping-bottle" element={<KeepingBottle />} />
          <Route path="/keeping-bottle/:id" element={<KeepingBottleDetail />} />
        </Route>
        <Route path="*" element={<div>404</div>} />
      </Routes>
    </Container>
  );
};
