// export const BASE_URL = 'https://portal-staging.loyal.id/api/v1/' //DEV
// export const CLIENT_CODE = '012302' //DEV

export const BASE_URL = 'https://portal-api-srv.loyal.id/api/v1/'
export const CLIENT_CODE = '920901'

// export const BASE_URL_KEEPING_BOTTLE = 'https://api-dev-webmember-pos.tudungsaji.id' //DEV
export const BASE_URL_KEEPING_BOTTLE = 'https://qlounge-web-member-api-srv.tudungsaji.id'
export const SESCRET_KEY ="qoinlounge-secret"

export const HELPER_URL = 'https://helperapi.loyal.id'
export const SECRET_CODE = '6LcMGjEUAAAAAH_TT2w_wfBCP9tYzuA3csPzp2wN'
export const SECRET_KEY_BOTTLE="bottle-secret"

export const STORAGE_ACCESS_KEY = 'ACCESS_TOKEN_KEY'
export const STORAGE_BOTTLE_KEY = 'BOTTLE_ACCESS_KEY'

