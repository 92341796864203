export const Images = {
  logoLoyalidBlue: require('./images/logo-loyalid-blue.svg').default,
  logoLoyalidWhite: require('./images/logo-loyalid-white.svg').default,
  logoClient: require('./images/client-logo.png')
}

export const Icons = {
  eyeOpen: require('./icons/eye-open.png'),
  eyeClose: require('./icons/eye-close.png'),
  redeemPoint: require('./icons/redeem-point.png'),
  luckydraw: require('./icons/luckydraw.png'),
}