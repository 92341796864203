import { FC } from 'react'

import './styles.scss'

interface Props {
  title: string
  onBack: () => void
}

export const Topbar: FC<Props> = ({ title, onBack }) => {
  return (
    <div className="topbar">
      <div
        className="back"
        onClick={onBack}
      >
        <img
          className="icon"
          src={require('assets/icons/arrow-left.png')}
        />
      </div>
      <div className="title">{title}</div>
    </div>
  )
}
