import { FC, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

import { useStoreActions, useStoreState } from 'stores'
import { CLIENT_CODE } from 'constant'

import { BottomSheet, Button, Loader, Topbar } from 'components'

import './styles.scss'
import { FetchStatus } from 'types'
import { QRFunc } from 'react-qrbtf'
import { RedeemVoucher } from 'stores/redeem'

export const Reward: FC = () => {
  const navigate = useNavigate()

  const [showCode, setShowCode] = useState<boolean>(false)
  const [data, setData] = useState<RedeemVoucher>()

  const { fetchRedeemVouchersUnused, fetchRedeemVouchersUsed } = useStoreActions(action => action.redeem.voucher)
  const { unused, used, statusUnused, statusUsed } = useStoreState(state => state.redeem.voucher)

  const limit = 20

  const getDataUnused = useCallback(() => {
    fetchRedeemVouchersUnused({
      paginator: {
        limit,
        page: 1,
      },
      status: 'not-used'
    })
  }, [fetchRedeemVouchersUnused])

  const getDataUsed = useCallback(() => {
    fetchRedeemVouchersUsed({
      paginator: {
        limit,
        page: 1,
      },
      status: 'used'
    })
  }, [fetchRedeemVouchersUsed])

  useEffect(() => {
    getDataUnused()
    getDataUsed()
  }, [])
  return (
    <div className="reward-container">
      <Topbar
        title="My Reward"
        onBack={() => navigate(-1)}
      />
      {statusUnused === FetchStatus.LOADING || statusUsed === FetchStatus.LOADING ?
        <Loader />
        :
        <div className="content">
          {unused.map((item, index) =>
            <div
              key={index}
              className="card"
            // onClick={() => navigate(`/catalogue/${item.id}`, { state: { data: item } })}
            >
              <img
                className="image"
                src={item.product.images[0]}
                alt="voucher"
              />
              <div className="detail">
                <div className="title">{item.product.name}</div>
                <div className="valid-until">Valid until:</div>
                <div className="date">
                  <img
                    className="icon"
                    src={require('assets/icons/date.png')}
                    alt="icon"
                  />
                  <span>{moment(item.expired_at).format('DD MMM YYYY')}</span>
                </div>
              </div>
              <div className="show-code">
                <Button
                  label="Show Code"
                  onClick={e => {
                    e.stopPropagation()
                    setShowCode(true)
                    setData(item)
                  }}
                />
              </div>
            </div>
          )}
          <BottomSheet
            isOpen={showCode}
            onChange={setShowCode}
          >
            <div className="voucher-code">
              <div className="title">{data?.product?.name}</div>
              <div className="info">Show this code to be scanned by the staff</div>
              <div className="qr-box">
                <QRFunc
                  className="qr-code"
                  value={data?.serial_no}
                  type="round"
                  posType="round"
                />
              </div>
              <div className="code">{data?.serial_no}</div>
            </div>
          </BottomSheet>
        </div>
      }
    </div>
  )
}
