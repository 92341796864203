import { action, thunk, thunkOn } from 'easy-peasy'

import { FetchStatus } from 'types'

import { EventItem, EventModel, eventService } from '.'

export const event: EventModel = {
  eventStatus: 'running',
  paginator: {
    current_page: 1,
    next_page: 0,
    per_page: 10,
    previous_page: 0,
    total_items: 0,
    total_pages: 1,
  },
  events: [],
  status: FetchStatus.LOADING,
  eventDetail: {} as EventItem,
  error: {},


  // Actions
  setEventStatus: action((state, payload) => {
    state.eventStatus = payload
  }),
  setPaginator: action((state, payload) => {
    state.paginator = payload
  }),
  setEvents: action((state, payload) => {
    state.events = payload
  }),
  addEvents: action((state, payload) => {
    state.events = [...state.events, ...payload]
  }),
  setEventDetail: action((state, payload) => {
    state.eventDetail = payload
  }),
  setStatus: action((state, payload) => {
    state.status = payload
  }),
  setError: action((state, payload) => {
    state.error = payload
  }),

  // Thunks
  fetchAllEvent: thunk((action, payload) => {
    action.setStatus(FetchStatus.LOADING)
    eventService
      .getAllEvent(payload)
      .then(({ data, paginator }) => {
        action.setPaginator(paginator)
        action.setEvents(data)
        action.setStatus(FetchStatus.LOADED)
        action.setError({})
      })
      .catch((e) => {
        action.setError(e)
        action.setStatus(FetchStatus.ERROR)
      })
  }),

  fetchMoreEvent: thunk((action, payload) => {
    action.setStatus(FetchStatus.FETCHING_MORE)
    eventService
      .getAllEvent(payload)
      .then(({ data, paginator }) => {
        action.setPaginator(paginator)
        action.addEvents(data)
        action.setStatus(FetchStatus.LOADED)
        action.setError({})
      })
      .catch(() => {
        action.setStatus(FetchStatus.FETCHING_MORE_ERROR)
        action.setStatus(FetchStatus.LOADED)
      })
  }),
  
  fetchEventDetail: thunk((action, payload) => {
    action.setStatus(FetchStatus.LOADING)
    eventService
      .getEventDetail(payload)
      .then(({ data }) => {
        action.setEventDetail(data)
        action.setStatus(FetchStatus.LOADED)
      })
      .catch((e) => {
        action.setError(e)
        action.setStatus(FetchStatus.ERROR)
      })
  }),
}
