import { action, thunk, computed } from 'easy-peasy'

import { FetchStatus } from 'types'
import { isEmpty } from 'utils'

import { ProductRedeem, ProductRedeemModel, productRedeemService } from '.'

export const productRedeemModel: ProductRedeemModel = {
  paginator: {
    current_page: 1,
    next_page: 1,
    per_page: 15,
    previous_page: 1,
    total_items: 0,
    total_pages: 1,
  },
  productRedeem: [],
  productRedeemDetail: {} as ProductRedeem,
  status: FetchStatus.LOADING,
  error: '',
  isEmpty: computed(
    (state) => !isEmpty(state.productRedeem) && state.status === FetchStatus.LOADED
  ),

  // Actions
  setPaginator: action((state, payload) => {
    state.paginator = payload
  }),
  setData: action((state, payload) => {
    state.productRedeem = payload
  }),
  addData: action((state, payload) => {
    state.productRedeem = [...state.productRedeem, ...payload]
  }),
  setDetail: action((state, payload) => {
    state.productRedeemDetail = payload
  }),
  setStatus: action((state, payload) => {
    state.status = payload
  }),
  setError: action((state, payload) => {
    state.error = payload
  }),

  // Thunks
  fetchProductRedeem: thunk(async (action, payload) => {
    try {
      action.setStatus(FetchStatus.LOADING)
      const res = await productRedeemService.fetchProductRedeem(payload)
      action.setPaginator(res.paginator)
      action.setData(res.data)
      action.setStatus(FetchStatus.LOADED)
      action.setError('')
    } catch (e: any) {
      action.setError(e?.response?.data?.message || 'Network Error')
      action.setStatus(FetchStatus.ERROR)
    }
  }),
  fetchMoreProductRedeem: thunk(async (action, payload) => {
    try {
      action.setStatus(FetchStatus.FETCHING_MORE)
      const res = await productRedeemService.fetchProductRedeem(payload)
      action.setPaginator(res.paginator)
      action.addData(res.data)
      action.setStatus(FetchStatus.LOADED)
      action.setError('')
    } catch (e: any) {
      action.setError(e?.response?.data?.message || 'Network Error')
      action.setStatus(FetchStatus.FETCHING_MORE_ERROR)
    }
  }),
  fetchProductRedeemDetail: thunk(async (action, payload) => {
    try {
      action.setStatus(FetchStatus.LOADING)
      const res = await productRedeemService.fetchProductRedeemDetail(payload)
      action.setDetail(res.data)
      action.setStatus(FetchStatus.LOADED)
      action.setError('')
    } catch (e: any) {
      action.setError(e?.response?.data?.message || 'Network Error')
      action.setStatus(FetchStatus.ERROR)
    }
  }),
}
