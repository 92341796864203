import { AxiosError } from "axios";
import { CLIENT_CODE } from "constant";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Alert, Button, Input } from "components";
import { api } from "utils";

type RegisterDto = {
  mobile_phone: string;
  password: string;
  confirm_password: string;
  phone_verification_code: string;
};

export const useRegister = (
  onError: (message: string) => void,
  onSuccess: (message: string) => void
): [boolean, (dto: RegisterDto) => Promise<void>] => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const submit = async (dto: RegisterDto) => {
    setIsLoading(true);

    try {
      const formData = new FormData();
      for (const key in dto) {
        if (key !== "confirm_password") {
          // @ts-ignore
          formData.append(key, dto[key]);
        }
      }
      formData.append("client_code", CLIENT_CODE);

      // @ts-ignore
      const res = await api.post<response>("/register/existing", formData, {
        headers: { "Content-Type": "multipart/form-data" },
        // transformRequest: formData => formData,
      });

      const { data } = res.data;

      onSuccess("Registrasi Berhasil Silahkan Login")

      // loggedIn(data.api_token)
      setIsLoading(false);
    } catch (e) {
      if (e instanceof AxiosError) {
        console.log("ERROR_LOGIN", e);
        setIsLoading(false);
        onError("Harap Masukan Nomor Handphone Dengan Benar");
      } else {
        console.log("Unexpected error", e);
      }
    }
  };

  return [isLoading, submit];
};
