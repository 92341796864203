import { FC, useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'

import { useStoreActions, useStoreState } from 'stores'
import { FetchStatus } from 'types'
import { NotificationItem } from 'stores/notification'

import { Loader, Topbar } from 'components'

import './styles.scss'
import { isEmpty } from 'utils'

export const NotificationDetail: FC = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const { fetchNotificationDetail } = useStoreActions(action => action.notification)
  const { detail, status } = useStoreState(state => state.notification)

  const [item, setItem] = useState<NotificationItem>(detail)

  let { id } = useParams()
  const data = location?.state?.data as NotificationItem

  useEffect(() => {
    if (id) fetchNotificationDetail(id)
  }, [fetchNotificationDetail, id])

  useEffect(() => {
    setItem(data || detail)
  }, [detail, data])

  return (
    <div className="notification-detail-container">
      {!isEmpty(item) &&
        <>
          <Topbar
            title={item.title}
            onBack={() => navigate(-1)}
          />
          <img
            className="image"
            src={item.image}
          />
          <div className="content">
            <div className="date">
              <img
                className="icon"
                src={require('assets/icons/date.png')}
                alt="icon"
              />
              <span>{moment(item.date).calendar()}</span>
            </div>
            <div
              className="body"
              dangerouslySetInnerHTML={{ __html: item.body }}
            />
          </div>
        </>
      }
    </div>
  )
}
