import { CLIENT_CODE } from 'constant'
import { api } from 'utils'

import { AuthResponse, LoginDto } from '.'

const login = async (dto: LoginDto): Promise<AuthResponse> => {
  try {
    const formData = new FormData()
    for (const key in dto) formData.append(key, dto[key as keyof LoginDto])
    formData.append('client_code', CLIENT_CODE)
    const res = await api.post<AuthResponse>('login', formData)
    const { data } = res
    return data
  } catch (e) {
    throw e
  }
}

export const AuthService = {
  login
}
