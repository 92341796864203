import { FC } from 'react'

import './styles.scss'

export const LoadMore: FC = () => {
  return (
    <div className="loadmore-container">
      <img
        className="loader"
        src={require('assets/images/load-more.svg').default}
      />
    </div>
  )
}
