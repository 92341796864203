import { FC, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";

import { useStoreActions, useStoreState } from "stores";
import { CLIENT_CODE } from "constant";

import { Loader, Topbar, LoadMore } from "components";

import "./styles.scss";
import { FetchStatus } from "types";

export const Store: FC = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);

  const { fetchAllOutlet, fetchMoreOutlet } = useStoreActions(
    (action) => action.outlet
  );
  const { outlets, status, paginator } = useStoreState((state) => state.outlet);

  const getOutlet = useCallback(() => {
    fetchAllOutlet({
      page,
      limit: 50,
      client_code: CLIENT_CODE,
    });
  }, [fetchAllOutlet]);

  const getMoreOutlet = useCallback(() => {
    fetchMoreOutlet({
      page,
      limit: 50,
      client_code: CLIENT_CODE,
    });
  }, [fetchMoreOutlet, page]);

  useEffect(() => {
    if (page === 1) {
      getOutlet();
    } else {
      getMoreOutlet();
    }
  }, [page]);

  return (
    <div className="store-container">
      <Topbar title="Store" onBack={() => navigate(-1)} />
      {status === FetchStatus.LOADING ? (
        <Loader />
      ) : (
        <div className="content">
          <InfiniteScroll
            dataLength={outlets?.length}
            next={() => setPage((page) => page + 1)}
            hasMore={outlets?.length < paginator?.total_items}
            loader={<LoadMore />}
            scrollableTarget="container"
          >
            {outlets.map((item, index) => (
              <div className="card" key={index}>
                <img className="image" src={item.image} alt="store-banner" />
                <div className="detail">
                  <div className="title">{item.name}</div>
                </div>
              </div>
            ))}
          </InfiniteScroll>
        </div>
      )}
    </div>
  );
};
