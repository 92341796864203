import { SESCRET_KEY, STORAGE_ACCESS_KEY, STORAGE_BOTTLE_KEY } from "constant";
import KJUR from "jsrsasign";
import { CreateTokenBottle } from "stores";

export const persistAccessToken = async (token: string): Promise<void> => {
  return localStorage.setItem(STORAGE_ACCESS_KEY, token);
};

export const destroyAccessToken = async (): Promise<void> => {
  return localStorage.removeItem(STORAGE_ACCESS_KEY);
};

export const getAccessToken = async (): Promise<string | null> => {
  return localStorage.getItem(STORAGE_ACCESS_KEY);
};

export const generateTokenBottle = async (
  params: CreateTokenBottle
): Promise<void> => {
  const header = { alg: "HS256", typ: "JWT" };
  const payload = params;
  const secretKey = SESCRET_KEY;
  const sHeader = JSON.stringify(header);
  const sPayload = JSON.stringify(payload);
  //@ts-ignore
  const token = KJUR.jws.JWS.sign("HS256", sHeader, sPayload, secretKey);

  return localStorage.setItem(STORAGE_BOTTLE_KEY, token)
};

export const getTokenBottle = async (): Promise<string | null> => {
  return localStorage.getItem(STORAGE_BOTTLE_KEY);
};

/* */
export const persistCardAccessToken = async (token: string): Promise<void> => {
  return localStorage.setItem(STORAGE_ACCESS_KEY, token);
};

export const destroyCardAccessToken = async (): Promise<void> => {
  return localStorage.removeItem(STORAGE_ACCESS_KEY);
};

export const getCardAccessToken = async (): Promise<string | null> => {
  return localStorage.getItem(STORAGE_ACCESS_KEY);
};
