import { action, thunk, computed } from "easy-peasy";

import { FetchStatus } from "types";
import { isEmpty } from "utils";

import { keepingBottleService, KeepingBottleModel, IKeepingBottle } from ".";

export const keepingbottle: KeepingBottleModel = {
  isEmpty: computed(
    (state) => state.status === FetchStatus.LOADED && isEmpty(state.bottle)
  ),
  paginator: {
    current_page: 1,
    next_page: 1,
    per_page: 10,
    previous_page: 1,
    total_items: 0,
    total_pages: 1,
  },
  bottle: [],
  bottleDetail: {} as IKeepingBottle,
  status: FetchStatus.LOADING,
  error: {},

  //Actions
  setPaginator: action((state, payload) => {
    state.paginator = payload;
  }),
  setBottle: action((state, payload) => {
    state.bottle = payload;
  }),
  setBottleDetail: action((state, payload) => {
    state.bottleDetail = payload;
  }),
  addBottle: action((state, payload) => {
    state.bottle = [...state.bottle, ...payload];
  }),
  setStatus: action((state, payload) => {
    state.status = payload;
  }),
  setError: action((state, payload) => {
    state.error = payload;
  }),

  fetchKeepingBottle: thunk(async (action, payload) => {
    try {
      const res = await keepingBottleService.fetchKeepingBottle(payload);
      action.setStatus(FetchStatus.LOADING);
      action.setBottle(res?.data);
      action.setPaginator(res.paginator);
      action.setStatus(FetchStatus.LOADED);
      action.setError({});
    } catch (e: any) {
      action.setError(e);
      action.setStatus(FetchStatus.ERROR);
    }
  }),

  fetchKeepingBottleDetail: thunk(async (action, payload) => {
    action.setStatus(FetchStatus.LOADING);
    keepingBottleService
      .getBottleDetail(payload)
      .then(({ data }) => {
        action.setBottleDetail(data)
      })
      .catch((e) => {
        action.setError(e);
        action.setStatus(FetchStatus.ERROR);
      });
  }),
};
