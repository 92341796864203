import { FC, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";

import { useStoreActions, useStoreState } from "stores";
import { CLIENT_CODE } from "constant";

import { LoadMore, Loader, Topbar } from "components";

import "./styles.scss";
import { FetchStatus } from "types";

export const Event: FC = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);

  const { fetchAllEvent, fetchMoreEvent } = useStoreActions(
    (action) => action.event
  );
  const { events, status, paginator } = useStoreState((state) => state.event);

  const getEvent = useCallback(() => {
    fetchAllEvent({
      page,
      limit: 50,
      client_code: CLIENT_CODE,
      status: "running",
    });
  }, [fetchAllEvent]);

  const getMoreEvent = useCallback(() => {
    fetchMoreEvent({
      page,
      limit: 50,
      client_code: CLIENT_CODE,
      status: "running",
    });
  }, [page, fetchMoreEvent]);

  useEffect(() => {
    if (page === 1) {
      getEvent();
    } else {
      getMoreEvent();
    }
  }, [page]);

  return (
    <div className="event-container">
      <Topbar title="Event" onBack={() => navigate(-1)} />
      {status === FetchStatus.LOADING ? (
        <Loader />
      ) : (
        <div className="content">
          <InfiniteScroll
            dataLength={events?.length}
            next={() => setPage((page) => page + 1)}
            hasMore={events?.length < paginator?.total_items}
            loader={<LoadMore />}
            scrollableTarget="container"
          >
            {events.map((item, index) => (
              <div
                className="card"
                key={index}
                onClick={() =>
                  navigate(`/event/${item.id}`, { state: { data: item } })
                }
              >
                <img
                  className="image"
                  src={item.photos[0]}
                  alt="promo-banner"
                />
                <div className="detail">
                  <div className="title">{item.name}</div>
                </div>
              </div>
            ))}
          </InfiniteScroll>
        </div>
      )}
    </div>
  );
};
