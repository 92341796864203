import { useState } from 'react'

import { FormStatus } from 'types'
import { api } from 'utils'

type UseForgotPasswordOption = {
  onError: (message: string) => void
}

export type ChangePasswordDto = {
  old_password: string
  new_password: string
}

export const useChangePassword = (callback?: UseForgotPasswordOption) => {
  const [status, setStatus] = useState<FormStatus>(FormStatus.IDLE)
  const [message, setMessage] = useState<string>('')

  const submit = async (dto: ChangePasswordDto) => {
    try {
      setStatus(FormStatus.LOADING)
      const res = await api.put('/customer/password', { ...dto })
      setStatus(FormStatus.SUCCESS)
      setMessage(res.data.message)
    } catch (e: any) {
      callback?.onError(e?.response?.data?.message || 'Network Error')
      setStatus(FormStatus.ERROR)
    } finally {
      setStatus(FormStatus.IDLE)
    }
  }

  return { status, message, submit }
}
