import { useState } from "react";
import { AxiosError } from "axios";

import { api } from "utils";
import { CLIENT_CODE, SECRET_CODE } from "constant";
import { useStoreActions } from "stores";
import { useNavigate } from "react-router-dom";

export type Response = {
  status: string;
  status_code: number;
  message: string;
  data: {
    next_action: string;
    id: number;
    code: string;
    barcode: string;
    name: string;
    id_number: string;
    profile_picture: null | string;
    active: boolean;
    total_expense: number;
    point: {
      redeem: number;
      lucky_draw: number;
    };
    api_token: string;
    id_type: null | string;
  };
};

type LoginDto = {
  phone: string;
  password: string;
};

export const useLogin = (
  onError: (message: string) => void
): [boolean, (dto: LoginDto) => Promise<void>, boolean,(dto: LoginDto) => Promise<void>] => {
  const { loggedIn } = useStoreActions((action) => action.auth);
  const [isLoading, setIsLoading] = useState(false);
  const [showInputPass, setShowInputPass] = useState(false);
  const navigate = useNavigate();

  const submit = async (dto: LoginDto) => {
    setIsLoading(true);
    console.log("button jalan")
    try {
      const formData = new FormData();
      // for (const key in dto) {
      //   // @ts-ignore
      //   formData.append(key, dto[key])
      // }
      formData.append("mobile_phone", dto.phone);
      formData.append("client_code", CLIENT_CODE);
      formData.append("name", dto.phone);
      formData.append("password", "123456");
      //@ts-ignore
      const res = await api.post<Response>(
        "/register/action/validate",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
          // transformRequest: formData => formData,
        }
      );
      const { data } = res.data;
      if (data.next_action === "New Register") {
        onError("Belum Menjadi Member, Silahkan Berbelanja di Qoinlounge");
      } else if (data.next_action === "Login") {
        setShowInputPass(true);
      } else {
        getSmsToken(dto);
      }
      // loggedIn(data.api_token)
      setIsLoading(false);
    } catch (e) {
      if (e instanceof AxiosError) {
        console.log("ERROR_LOGIN", e);
        setIsLoading(false);
        onError("Harap Masukan Nomor Handphone Dengan Benar");
      } else {
        console.log("Unexpected error", e);
      }
    }
  };

  const getSmsToken = (dto: LoginDto) => {
    setIsLoading(true);
    api
      .post("/sms-token", {
        client_code: CLIENT_CODE,
        secret_code: SECRET_CODE,
      })
      .then((res) => {
        setIsLoading(false);
        sendOtp(res.data.data.sms_token, dto);
      })
      .catch((err) => {
        onError("Unexpected Error");
        setIsLoading(false);
      });
  };

  const sendOtp = (params: string, dto: LoginDto) => {
    const data = {
      mobile_phone: dto.phone,
      sms_token: params,
      client_code: CLIENT_CODE,
      type: "Existing Registration",
    };

    setIsLoading(true);
    api
      .post("verification/phone/send", data)
      .then((response) => {
        navigate("/register", { state: data });
      })
      .catch((err) => {
        console.log(err);
        onError("Unexpected Error");
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleLogin = async (dto: LoginDto) => {
    setIsLoading(true)
    try {
      const formData = new FormData()
      for (const key in dto) {
        // @ts-ignore
        formData.append(key, dto[key])
      }
      formData.append('client_code', CLIENT_CODE)
      const res = await api.post<Response>('/login',
        formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        // transformRequest: formData => formData,
      })
      const { data } = res.data
      loggedIn(data.api_token)
    } catch (e) {
      if (e instanceof AxiosError) {
        console.log('ERROR_LOGIN', e)
        setIsLoading(false)
        onError(
          e?.response?.data?.message || 'Network Error',
        )
      } else {
        console.log('Unexpected error', e)
      }
    }
  }

  return [isLoading, submit, showInputPass, handleLogin];
};
