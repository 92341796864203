import { FC, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";

import { useStoreActions, useStoreState } from "stores";

import { LoadMore, Loader, Topbar } from "components";

import "./styles.scss";
import { FetchStatus } from "types";

export const Catalogue: FC = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);

  const { fetchProductRedeem, fetchMoreProductRedeem } = useStoreActions(
    (action) => action.redeem.product
  );
  const { productRedeem, status, paginator } = useStoreState(
    (state) => state.redeem.product
  );

  const getProductRedeem = useCallback(() => {
    fetchProductRedeem({
      page,
      limit: 10,
      status: "all",
      type: "all",
      source: "all",
    });
  }, [fetchProductRedeem]);

  const getMoreProductRedeem = useCallback(() => {
    fetchMoreProductRedeem({
      page,
      limit: 10,
      status: "all",
      type: "all",
      source: "all",
    });
  }, [fetchMoreProductRedeem, page]);

  useEffect(() => {
    if (page === 1) {
      getProductRedeem();
    } else {
      getMoreProductRedeem();
    }
  }, [getProductRedeem, page]);

  return (
    <div className="catalogue-container">
      <Topbar title="Popular Catalogue" onBack={() => navigate(-1)} />
      {status === FetchStatus.LOADING ? (
        <Loader />
      ) : (
        <InfiniteScroll
          dataLength={productRedeem?.length}
          next={() => setPage((page) => page + 1)}
          hasMore={productRedeem?.length < paginator?.total_items}
          loader={<LoadMore />}
          scrollableTarget="container"
          className="content"
        >
          {productRedeem.map((item, index) => (
            <div
              key={index}
              className="card"
              onClick={() =>
                navigate(`/catalogue/${item.id}`, { state: { data: item } })
              }
            >
              <div className="image">
                <img src={item.images[0]} alt="promo-banner" />
              </div>
              <div className="detail">
                <div className="availability">{item.name}</div>
                <div className="point">{item.point} Points</div>
              </div>
            </div>
          ))}
        </InfiniteScroll>
      )}
    </div>
  );
};
