import { FC, InputHTMLAttributes, useEffect, useState } from 'react'

import './styles.scss'
import { Icons } from 'assets'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  name: string
}

export const InputPassword: FC<Props> = ({ label, name, ...props }) => {
  const [showPassword, setShowPassword] = useState<boolean>(false)

  return (
    <div className="input-wrapper">
      <input id={name} type={showPassword ? 'text' : 'password'} {...props} />
      <div
        className="eye"
        onClick={() => setShowPassword(!showPassword)}
      >
        <img src={showPassword ? Icons.eyeOpen : Icons.eyeClose} alt="eye" />
      </div>
    </div>
  )
}