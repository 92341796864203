import { ProductRedeemModel, productRedeemModel } from './product'
import { RedeemProductModel, redeemProductModel } from './redeem'
import { RedeemVoucherModel, redeemVoucherModel } from './voucher'

export * from './product'
export * from './redeem'
export * from './voucher'

export interface RedeemModel {
  product: ProductRedeemModel,
  redeem: RedeemProductModel,
  voucher: RedeemVoucherModel
}

export const redeem: RedeemModel = {
  product: productRedeemModel,
  redeem: redeemProductModel,
  voucher: redeemVoucherModel
}
