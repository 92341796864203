import { api, getAccessToken } from 'utils'
import { Data, PaginationData } from 'types'
import { CLIENT_CODE } from 'constant'

import { FetchNotificationParams, NotificationItem } from '.'

const fetchNotification = async (
  params: FetchNotificationParams
): Promise<PaginationData<NotificationItem>> => {
  const accessToken = await getAccessToken()
  try {
    const res = await api.get<PaginationData<NotificationItem>>(
      '/push-notification',
      {
        params: {
          access_token: accessToken,
          page: params.page,
          limit: params.limit,
          code_client: CLIENT_CODE,
          type: 'all'
        }
      }
    )
    const { data } = res
    return data
  } catch (e: any) {
    throw e
  }
}

const fetchNotificationDetail = async (
  id: string
): Promise<Data<NotificationItem>> => {
  const accessToken = await getAccessToken()
  try {
    const res = await api.get<Data<NotificationItem>>(`/push-notification/${id}?client_code=${CLIENT_CODE}`)
    const { data } = res
    return data
  } catch (e: any) {
    throw e
  }
}

export const historyService = {
  fetchNotification,
  fetchNotificationDetail
}
