import { FC } from 'react'

import './styles.scss'

interface Props {
  title: string
  message: string
  onClick: () => void
}

export const Alert: FC<Props> = ({ title, message, onClick }) => {
  return (
    <div className="alert-container">
      <div className="message-box">
        <div className="alert-title">{title}</div>
        <div className="alert-message">{message}</div>
        <div
          className="button-alert"
          onClick={onClick}
        >
          OK
        </div>
      </div>
    </div>
  )
}
