import { FC, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { Images } from 'assets'
import { useStoreActions, useStoreState } from 'stores'
import { AuthStatus } from 'types'

import './styles.scss'

export const Splash: FC = () => {
  const navigate = useNavigate()

  const { authStatus, isAuthenticated } = useStoreState((state) => state.auth)
  const { checkAuth } = useStoreActions((action) => action.auth)

  useEffect(() => {
    checkAuth()
  }, [checkAuth])

  console.log('AUTH_STATUS', authStatus, 'IS_AUTHENTICATED', isAuthenticated)

  useEffect(() => {
    setTimeout(() => {
      if (!isAuthenticated && authStatus !== AuthStatus.LOADING) {
        navigate('/login')
      } else {
        navigate('/home')
      }
    }, 3000)
  }, [authStatus, isAuthenticated, navigate])
  return (
    <div className="splash-container">
      <img
        className="logo-client"
        src={Images.logoClient}
        alt="logo"
      />
      {/* <div className="powered-by">
        <div className="label">Powered by</div>
        <img
          className="logo-loyalid"
          src={Images.logoLoyalidBlue}
          alt="logo"
        />
      </div> */}
    </div>
  )
}
