import { FC, useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Scrollbar } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/scrollbar'

import { Icons } from 'assets'
import { useStoreActions, useStoreState } from 'stores'
import { CLIENT_CODE } from 'constant'
import { formatCurrency } from 'utils'

import './styles.scss'
import { Loader, Topbar } from 'components'
import { FetchStatus } from 'types'

export const RewardBenefit: FC = () => {
  const navigate = useNavigate()

  const { isAuthenticated } = useStoreState((state) => state.auth)

  const { fetchProfile } = useStoreActions(action => action.profile)
  const { profile, status: statusProfile } = useStoreState(state => state.profile)

  useEffect(() => {
    if (isAuthenticated) fetchProfile()
  }, [isAuthenticated, fetchProfile])

  useEffect(() => {
    fetchProfile()
  }, [])

  const loading = statusProfile === FetchStatus.LOADING

  return (
    <div className="reward-benefit-container">
      {loading && <Loader />}
      <Topbar
        title="Reward Benefit"
        onBack={() => navigate('/home')}
      />
      <div className="member-card">
        <div className="top">
          <div className="name">{profile?.name}</div>
          <div className="badge">
            <img
              className="icon"
              src={require('assets/icons/badge.png')}
              alt="icon"
            />
            <span>{profile?.card_type?.name}</span>
            <img
              className="chevron"
              src={require('assets/icons/chevron-right.png')}
              alt="icon"
            />
          </div>
        </div>
        <div className="detail">
          <div className="card">
            <div className="label">Your Expense</div>
            <div className="expense">
              <span>IDR</span> {formatCurrency(profile.total_expense | 0)}
            </div>
            <div className="progressbar">
              <div className="progress" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
