import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { QRFunc } from "react-qrbtf";

import { ProductRedeem } from "stores/redeem";

import { Topbar, BottomSheet, Button, Alert } from "components";

import "./styles.scss";
import { useStoreActions, useStoreState } from "stores";
import { isEmpty } from "utils";
import { FormStatus } from "types";

export const CatalogueDetail: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { fetchProductRedeemDetail } = useStoreActions(
    (action) => action.redeem.product
  );

  const { productRedeemDetail, status } = useStoreState(
    (state) => state.redeem.product
  );

  const { fetchProfile } = useStoreActions((action) => action.profile);
  const { profile, status: statusProfile } = useStoreState(
    (state) => state.profile
  );

  const { submit, setError: setErrorRedeem } = useStoreActions(
    (action) => action.redeem.redeem
  );
  const { status: statusRedeem, error: errorRedeem } = useStoreState(
    (state) => state.redeem.redeem
  );

  const [item, setItem] = useState<ProductRedeem>(productRedeemDetail);
  const [error, setError] = useState<string>("");

  let { id } = useParams();
  const data = location?.state?.data as ProductRedeem;

  useEffect(() => {
    fetchProfile();
  }, []);

  useEffect(() => {
    if (!isEmpty(errorRedeem)) {
      setError(errorRedeem);
    }
  }, [errorRedeem]);

  useEffect(() => {
    if (id) fetchProductRedeemDetail(id);
  }, [fetchProductRedeemDetail, id]);

  useEffect(() => {
    setItem(data || productRedeemDetail);
  }, [productRedeemDetail, data]);

  const [open, setOpen] = useState<boolean>(false);

  const onClickError = () => {
    setErrorRedeem("");
    setError("");
  };
  return (
    <div className="catalogue-detail-container">
      {!isEmpty(item) && (
        <>
          <Topbar title={item.name} onBack={() => navigate(-1)} />
          <img className="image" src={item.images[0]} alt="promo-banner" />
          <div className="detail">
            <div className="label">Availability:</div>
            <div className="date">
              {moment(item.period.start).format("DD MMM YYYY")} -{" "}
              {moment(item.period.end).format("DD MMM YYYY")}
            </div>
            <div className="space" />
            <div className="note">{item.note}</div>
            <div className="space" />
            <div className="label">Terms and Conditions:</div>
            {item?.terms?.split("\n").map((text: string, index: number) => (
              <div className="note" key={index}>
                {text}
              </div>
            ))}
            <div className="space" />
            <div className="label">How to use:</div>
            {item?.instructions
              ?.split("\n")
              .map((text: string, index: number) => (
                <div className="note" key={index}>
                  {text}
                </div>
              ))}
          </div>
          <div className="bottom-action">
            <Button label="Redeem Now" onClick={() => setOpen(true)} />
            <BottomSheet isOpen={open} onChange={setOpen}>
              <div className="bottom-sheet-content">
                <div className="title">{item.name}</div>
                <div className="point">{item.point} Points</div>
                <div className="footer">
                  <div className="my-point">
                    <div className="label">My Points</div>
                    <div className="point">
                      {profile?.point?.redeem | 0} Points
                    </div>
                  </div>
                  <Button
                    label="Redeem"
                    isLoading={statusRedeem === FormStatus.LOADING}
                    onClick={() => {
                      submit({
                        products: [{ id: data.id, qty: 1 }],
                        source: data.source,
                      });
                    }}
                  />
                </div>
              </div>
            </BottomSheet>
          </div>
        </>
      )}
      {!isEmpty(error) && (
        <Alert title="Redeem Failed" message={error} onClick={onClickError} />
      )}
      {statusRedeem === FormStatus.SUCCESS && (
        <Alert
          title="Redeem Success"
          message=""
          onClick={() => navigate("/reward")}
        />
      )}
    </div>
  );
};
