import { FC, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Scrollbar } from "swiper/modules";
import "swiper/css";
import "swiper/css/scrollbar";

import { AiOutlineRight } from "react-icons/ai";

import { Icons } from "assets";
import { useStoreActions, useStoreState } from "stores";
import { CLIENT_CODE } from "constant";
import { formatCurrency } from "utils";

import "./styles.scss";
import { Loader } from "components";
import { FetchStatus } from "types";
import classNames from "classnames";

export const Home: FC = () => {
  const navigate = useNavigate();

  const { isAuthenticated } = useStoreState((state) => state.auth);
  // const { loggedOut } = useStoreActions((action) => action.auth)
  const { fetchProfile } = useStoreActions((action) => action.profile);
  const { profile, status: statusProfile } = useStoreState(
    (state) => state.profile
  );

  const { fetchAllPromo } = useStoreActions((action) => action.promo);
  const { promos, status: statusPromo } = useStoreState((state) => state.promo);

  const { fetchProductRedeem } = useStoreActions(
    (action) => action.redeem.product
  );
  const { productRedeem, status: statusProductRedeem } = useStoreState(
    (state) => state.redeem.product
  );

  const getPromo = useCallback(() => {
    fetchAllPromo({
      page: 1,
      limit: 10,
      client_code: CLIENT_CODE,
      status: "running",
    });
  }, [fetchAllPromo]);

  const getProductRedeem = useCallback(() => {
    fetchProductRedeem({
      page: 1,
      limit: 10,
      status: "all",
      type: "all",
      source: "all",
    });
  }, [fetchProductRedeem]);

  useEffect(() => {
    if (isAuthenticated) fetchProfile();
  }, [isAuthenticated, fetchProfile]);

  useEffect(() => {
    fetchProfile();
    getPromo();
    getProductRedeem();
  }, []);


  const loading =
    statusProfile === FetchStatus.LOADING ||
    statusPromo === FetchStatus.LOADING ||
    statusProductRedeem === FetchStatus.LOADING;

  return (
    <div className="home-container">
      {loading && <Loader />}
      <div className="header">
        <img
          className="client-logo"
          src={require("assets/images/client-logo.png")}
          alt="logo"
        />
        <div className="header-action">
          <div className="button-action" onClick={() => navigate("/profile")}>
            <img
              className="icon"
              src={require("assets/icons/account.png")}
              alt="icon"
            />
          </div>
          <div
            className="button-action"
            onClick={() => navigate("/notification")}
          >
            <img
              className="icon"
              src={require("assets/icons/bell.png")}
              alt="icon"
            />
          </div>
        </div>
      </div>
      <div
        className="point-luckydraw"
        onClick={() => navigate("/point-lucky-number")}
      >
        <div className="top">
          <div className="title">Point & Lucky Number</div>
          <img
            className="arrow"
            src={require("assets/icons/arrow-right.png")}
            alt="icon"
          />
        </div>
        <div className="detail">
          <div className="card">
            <div className="icon-box">
              <img className="icon" src={Icons.redeemPoint} alt="icon" />
            </div>
            <div className="amount">
              <div className="number">{profile?.point?.redeem}</div>
              <div className="label">Redeem Points</div>
            </div>
          </div>
          <div className="card">
            <div className="icon-box">
              <img className="icon" src={Icons.luckydraw} alt="icon" />
            </div>
            <div className="amount">
              <div className="number">{profile?.point?.lucky_draw}</div>
              <div className="label">Lucky Draw</div>
            </div>
          </div>
        </div>
      </div>
      <div className="member-card" onClick={() => navigate("/reward/benefit")}>
        <div className="top">
          <div className="name">{profile?.name}</div>
          <div className="badge">
            <img
              className="icon"
              src={require("assets/icons/badge.png")}
              alt="icon"
            />
            <span>{profile?.card_type?.name}</span>
            <img
              className="chevron"
              src={require("assets/icons/chevron-right.png")}
              alt="icon"
            />
          </div>
        </div>
        <div className="detail">
          <div className="card">
            <div className="label">Your Expense</div>
            <div className="expense">
              <span>IDR</span> {formatCurrency(profile.total_expense | 0)}
            </div>
            <div className="progressbar">
              <div className="progress" />
            </div>
          </div>
          <div className="navigation">
            <div
              className="card"
              onClick={(e) => {
                e.stopPropagation();
                navigate("/reward");
              }}
            >
              <img
                className="icon"
                src={require("assets/icons/gift.png")}
                alt="icon"
              />
            </div>
            <div
              className="card"
              onClick={(e) => {
                e.stopPropagation();
                navigate("/history");
              }}
            >
              <img
                className="icon"
                src={require("assets/icons/history.png")}
                alt="icon"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="features">
        <div className="menu">
          <div className="card" onClick={() => navigate("/catalogue")}>
            <img
              className="icon"
              src={require("assets/icons/catalogue.png")}
              alt="icon"
            />
            <div className="label">Catalogue</div>
          </div>
          <div className="card" onClick={() => navigate("/store")}>
            <img
              className="icon"
              src={require("assets/icons/store.png")}
              alt="icon"
            />
            <div className="label">Store</div>
          </div>
          <div className="card" onClick={() => navigate("/promotion")}>
            <img
              className="icon"
              src={require("assets/icons/promotions.png")}
              alt="icon"
            />
            <div className="label">Promotion</div>
          </div>
          <div className="card" onClick={() => navigate("/event")}>
            <img
              className="icon"
              src={require("assets/icons/calendar.png")}
              alt="icon"
            />
            <div className="label">Event</div>
          </div>
          <div className="card" onClick={() => navigate("/convert-point")}>
            <img
              className="icon"
              src={require("assets/icons/convert.png")}
              alt="icon"
            />
            <div className="label">Convert</div>
          </div>
          <div className="card" onClick={() => navigate("/keeping-bottle")}>
            <img
              className="icon"
              src={require("assets/icons/bottle.png")}
              alt="icon"
            />
            <div className="label">Keeping Bottle</div>
          </div>
        </div>
      </div>
      <div className="promotion">
        <div className="top">
          <div className="title">Promotion</div>
          <div className="see-all" onClick={() => navigate("/promotion")}>
            <span>See All</span>
            <AiOutlineRight color="#FFC60C" />
          </div>
        </div>
        <Swiper
          className="mySwiper"
          modules={[Autoplay, Scrollbar]}
          spaceBetween={0}
          slidesPerView={1}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          scrollbar={{
            hide: false,
          }}
          // autoHeight
          loop
        >
          {promos.map((item, index) => (
            <SwiperSlide key={index}>
              <img
                className="banner"
                src={item.photos[0]}
                alt="promo-banner"
                onClick={() =>
                  navigate(`/promotion/${item.id}`, { state: { data: item } })
                }
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="catalogue">
        <div className="top">
          <div className="title">Popular Catalogue</div>
          <div className="see-all" onClick={() => navigate("/catalogue")}>
            <span>See All</span>
            <AiOutlineRight color="#FFC60C" />
          </div>
        </div>
        <div className='products'>
          {productRedeem.map((item, index) => (
            <div
              key={index}
              className="card"
              onClick={() =>
                navigate(`/catalogue/${item.id}`, { state: { data: item } })
              }
            >
              <div className="image">
                <img src={item.images[0]} />
                <div className="point">{item.point} Points</div>
              </div>
              <div className="detail">
                <div className="name">{item.name}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
