import { api, getAccessToken } from 'utils'
import { PaginationData } from 'types'

import { FetchHistoryParams, Transaction } from '.'
import { CLIENT_CODE } from 'constant'

const fetchHistory = async (
  params: FetchHistoryParams
): Promise<PaginationData<Transaction>> => {
  const accessToken = await getAccessToken()
  try {
    const res = await api.get<PaginationData<Transaction>>(
      '/customer/transaction/history',
      {
        params: {
          access_token: accessToken,
          page: params.page,
          limit: params.limit,
          code_client: CLIENT_CODE,
          type: params.type,
          active: 1
        }
      }
    )
    const { data } = res
    return data
  } catch (e: any) {
    throw e
  }
}

export const historyService = {
  fetchHistory
}
