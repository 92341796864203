import { api } from 'utils'

import {
  GetAllOutletResponse,
  GetAllOutletParams,
  OutletService
} from '.'

const getAllOutlet = async (
  params: GetAllOutletParams,
): Promise<GetAllOutletResponse> => {
  try {
    const res = await api.get<GetAllOutletResponse>('/master/store', { params })
    return res.data
  } catch (e) {
    throw e
  }
}

export const outletService: OutletService = {
  getAllOutlet
}
