import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import "./styles.scss";
import { IKeepingBottle, useStoreActions, useStoreState } from "stores";
import { isEmpty } from "utils";
import { Barcode, Topbar } from "components";
import { SECRET_KEY_BOTTLE } from "constant";
import KJUR from "jsrsasign";
import { MdBrokenImage } from "react-icons/md";

export const KeepingBottleDetail: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [item, setItem] = useState<IKeepingBottle>();
  const [token, setToken] = useState("");

  let { id } = useParams();
  const data = location?.state?.data as IKeepingBottle;
  const { bottleDetail } = useStoreState((state) => state.keepingbottle);

  const { fetchKeepingBottleDetail } = useStoreActions(
    (action) => action.keepingbottle
  );

  useEffect(() => {
    if (id) fetchKeepingBottleDetail(id);
  }, [fetchKeepingBottleDetail, id]);

  useEffect(() => {
    setItem(data || bottleDetail);
    const token = generateToken();
    setToken(token);
  }, [bottleDetail, data]);

  const generateToken = () => {
    const header = { alg: "HS256", typ: "JWT" };
    const payload = id;
    const secretKey = SECRET_KEY_BOTTLE;
    const sHeader = JSON.stringify(header);
    const sPayload = JSON.stringify(payload);
    //@ts-ignore
    const token = KJUR.jws.JWS.sign("HS256", sHeader, sPayload, secretKey);

    return token;
  };

  return (
    <div className="bottle-detail-container">
      {!isEmpty(item) && (
        <>
          <Topbar title="Keeping Bottle" onBack={() => navigate(-1)} />
          <div className="content">
            <div className="image">{token && <Barcode data={token} />}</div>
            <div className="detail">
              <div className="label">Product Name</div>
              <div className="title">{item?.name}</div>

              <div className="label">Phone Number</div>
              <div className="title">{item?.phone}</div>

              <div className="label">Keeping Date</div>
              <div className="date">
                <img
                  className="icon"
                  src={require("assets/icons/date.png")}
                  alt="icon"
                />
                <span>{moment(item?.keeping_at).format("D MMMM YYYY")}</span>
              </div>

              <div className="label">Expired At</div>
              <div className="date">
                <img
                  className="icon"
                  src={require("assets/icons/date.png")}
                  alt="icon"
                />
                <span>
                  {moment(item?.keeping_expired_at).format("D MMMM YYYY")}
                </span>
              </div>
              <div className="label">Photo</div>

              {item?.label_photo_url && item?.photo_url ? (
                <div className="not-available-photo">
                  <MdBrokenImage color="black" size={150} />
                  <p className="title">We Are Sorry Photo Not Available</p>
                </div>
              ) : (
                <div className="photo">
                  {item?.photo_url && (
                    <img
                      src={item?.photo_url}
                      alt="Bottle Image"
                      width={150}
                      height={150}
                    />
                  )}
                  {item?.label_photo_url && (
                    <img
                      src={item?.label_photo_url}
                      alt="Bottle Image"
                      width={150}
                      height={150}
                    />
                  )}
                </div>
              )}

              {item?.pickup_date && (
                <>
                  <div className="label">Pickup Date</div>
                  <div className="date">
                    <img
                      className="icon"
                      src={require("assets/icons/date.png")}
                      alt="icon"
                    />
                    <span>
                      {moment(item?.pickup_date).format("D MMMM YYYY")}
                    </span>
                  </div>

                  <div className="label">Pickup Photo</div>
                  <div className="photo">
                    {item?.pickup_photo_url ? (
                      item?.pickup_photo_url && (
                        <img
                          src={item?.pickup_photo_url}
                          alt="Pickup Photo Url"
                          width={150}
                          height={150}
                        />
                      )
                    ) : (
                      <div className="not-available-photo">
                        <MdBrokenImage color="black" size={150} />
                        <p className="title">
                          We Are Sorry Photo Not Available
                        </p>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
