import { FC, FormEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";

import { Alert, Button, Input } from "components";
import { useStoreActions, useStoreState } from "stores";
import { isEmpty } from "utils";

import { useLogin } from "./hooks";

import "./styles.scss";

export const Login: FC = () => {
  const navigate = useNavigate();

  const { isAuthenticated } = useStoreState((state) => state.auth);
  const { fetchProfile } = useStoreActions((action) => action.profile);
  const { profile } = useStoreState((state) => state.profile);

  const [error, setError] = useState<string>("");
  const [isLoading, submit, showInputPass, handleLogin] = useLogin(setError);

  const SignInSchema = yup.object().shape({
    phone: yup.number().required("Required"),
    password: yup.string().required("Required"),
  });

  const { handleChange,  values } = useFormik({
    initialValues: {
      phone: process.env.NODE_ENV === "development" ? "087720600344" : "",
      password: process.env.NODE_ENV === "development" ? "imam123" : "",
    },
    validationSchema: SignInSchema,
    onSubmit: (values) => submit(values),
  });

  useEffect(() => {
    if (isAuthenticated) fetchProfile();
  }, [isAuthenticated, fetchProfile]);

  useEffect(() => {
    if (!isEmpty(profile) && isAuthenticated)
      navigate("/home", { replace: true });
  }, [profile, navigate]);

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if(showInputPass){
      handleLogin(values)
    }else{
      submit(values)
    }
  }

  return (
    <div className="login-container">
      <div className="content">
        <div className="title">Sign In</div>
        <div className="subtitle">Sign in to your account</div>
        <form
          onSubmit={(e) => onSubmit(e)}
          className="form"
        >
          <Input.Text
            name="phone"
            placeholder="Enter your phone number"
            onChange={handleChange}
            value={values.phone}
          />
          {showInputPass && (
            <Input.Password
              name="password"
              placeholder="Enter your password"
              onChange={handleChange}
              value={values.password}
            />
          )}
          <Button type="submit" label="Login" isLoading={isLoading} />
        </form>
      </div>
      {!isEmpty(error) && (
        <Alert
          title="Login Failed"
          message={error}
          onClick={() => setError("")}
        />
      )}
    </div>
  );
};
