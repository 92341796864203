import { FC, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";

import { useStoreActions, useStoreState } from "stores";
import { CLIENT_CODE } from "constant";

import { Topbar, LoadMore, Loader } from "components";

import "./styles.scss";
import { FetchStatus } from "types";

export const Promotion: FC = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);

  const { fetchAllPromo, fetchMorePromo } = useStoreActions(
    (action) => action.promo
  );
  const { promos, paginator, status } = useStoreState((state) => state.promo);

  const getPromo = useCallback(() => {
    fetchAllPromo({
      page,
      limit: 10,
      client_code: CLIENT_CODE,
      status: "running",
    });
  }, [fetchAllPromo]);

  const getMorePromo = useCallback(() => {
    fetchMorePromo({
      page,
      limit: 10,
      client_code: CLIENT_CODE,
      status: "running",
    });
  }, [page, fetchMorePromo]);

  useEffect(() => {
    if (page === 1) {
      getPromo();
    } else {
      getMorePromo();
    }
  }, [page]);

  return (
    <div className="promotion-container">
      <Topbar title="Promotion" onBack={() => navigate(-1)} />
      {status === FetchStatus.LOADING ? (
        <Loader />
      ) : (
        <div className="content">
          <InfiniteScroll
            dataLength={promos?.length}
            next={() => setPage((page) => page + 1)}
            hasMore={promos?.length < paginator?.total_items}
            loader={<LoadMore />}
            scrollableTarget="container"
          >
            {promos.map((item, index) => (
              <div
                key={index}
                className="card"
                onClick={() =>
                  navigate(`/promotion/${item.id}`, { state: { data: item } })
                }
              >
                <img
                  className="image"
                  src={item.photos[0]}
                  alt="promo-banner"
                />
                <div className="detail">
                  <div className="title">{item.name}</div>
                  <div className="date">
                    <img
                      className="icon"
                      src={require("assets/icons/date.png")}
                      alt="icon"
                    />
                    <span>
                      {moment(item.period.start).format("DD MMM YYYY")} -{" "}
                      {moment(item.period.end).format("DD MMM YYYY")}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </InfiniteScroll>
        </div>
      )}
    </div>
  );
};
