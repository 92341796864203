import { FC, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

import { useStoreActions, useStoreState } from 'stores'
import { CLIENT_CODE } from 'constant'

import { Alert, Button, Input, Loader, Topbar } from 'components'

import './styles.scss'
import { FetchStatus } from 'types'
import { number } from 'yup'
import { api, getAccessToken, isEmpty } from 'utils'
import { useLuckyNumber } from './hooks'

type ConvertInquiry = {
  ld_conversion_limit: number
  lucky_draw: number
  redeem_point: number
}

export const PointLuckyNumber: FC = () => {
  const navigate = useNavigate()

  const { isAuthenticated } = useStoreState((state) => state.auth)
  const { fetchProfile } = useStoreActions(action => action.profile)
  const { profile, status: statusProfile } = useStoreState(state => state.profile)

  const { data, isLoading, refetch } = useLuckyNumber()

  useEffect(() => {
    if (isAuthenticated) fetchProfile()
  }, [isAuthenticated, fetchProfile])

  useEffect(() => {
    fetchProfile()
  }, [])
  return (
    <div className="point-lucky-number-container">
      <Topbar
        title="Point & Lucky Number"
        onBack={() => navigate(-1)}
      />
      <div className="header">
        <div className="redeem-point">
          {profile?.point?.redeem} Pt
        </div>
        <div className="label">Your redeem point</div>
      </div>
      <div className="content">
        <div
          className="luckydraw-card"
        >
          <div className="top">
            <div className="name">Luckydraw</div>
          </div>
          <div className="detail">
            <div className="card">
              You have {data.length} lucky draw numbers wait to draw
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
