import React, { FC, useCallback, useEffect, useState } from "react";
import {
  FetchKeepingBottleParams,
  IKeepingBottle,
  useStoreActions,
  useStoreState,
} from "stores";
import "./styles.scss";
import { LoadMore, Loader, Topbar } from "components";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { AiOutlineRight } from "react-icons/ai";
import InfiniteScroll from "react-infinite-scroll-component";
import { FetchStatus } from "types";
import classNames from "classnames";

export const KeepingBottle: FC = () => {
  const { profile } = useStoreState((state) => state.profile);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [selectedTab, setSelectedTab] = useState("AVAILABLE");
  const [item, setItem] = useState<IKeepingBottle[]>([]);
  const [expiredData, setExpiredData] = useState<IKeepingBottle[]>([]);

  const isKeepingBottle = () => selectedTab === "AVAILABLE";
  const isHistory = () => selectedTab === "HISTORY";

  const { fetchKeepingBottle } = useStoreActions(
    (action) => action.keepingbottle
  );

  const { bottle, status, paginator } = useStoreState(
    (state) => state.keepingbottle
  );

  const bottleStatus = bottle.length !== 0;

  const filteredBottle = () => {
    const now = new Date();
    const validItems = bottle.filter((item) => {
      if (item.keeping_expired_at) {
        const expirationDate = new Date(item.keeping_expired_at);
        return !item.pickup_date && expirationDate > now;
      }
      return false;
    });
    setItem((prevData) => [...prevData, ...validItems]);

    const expiredAndPickedUpItems = bottle.filter((item) => {
      if (item.keeping_expired_at) {
        const expirationDate = new Date(item.keeping_expired_at);
        const pickupDate = item.pickup_date ? new Date(item.pickup_date) : null;

        const isPickedUpAndNotExpired =
          pickupDate && expirationDate > now && pickupDate <= now;

        const isExpiredAndNotPickedUp =
          expirationDate <= now && (!pickupDate || pickupDate <= now);

        return isPickedUpAndNotExpired || isExpiredAndNotPickedUp;
      }
      return false;
    });

    setExpiredData((prevData) => [...prevData, ...expiredAndPickedUpItems]);
  };

  const fetchBottle = useCallback(() => {
    const params: FetchKeepingBottleParams = {
      page,
      limit: 50,
      sort_by: "latest",
    };
    fetchKeepingBottle(params);
  }, [fetchKeepingBottle]);

  useEffect(() => {
    if (page === 1) {
      fetchBottle();
      filteredBottle();
    }
  }, [page, bottleStatus]);

  return (
    <>
      <div className="bottle-container">
        <Topbar title="Keeping Bottle" onBack={() => navigate(-1)} />
        <div className="content">
          <div className="category">
            <div
              className={classNames({ active: selectedTab === "AVAILABLE" })}
              onClick={() => setSelectedTab("AVAILABLE")}
            >
              <span>AVAILABLE</span>
            </div>
            <div
              className={classNames({ active: selectedTab === "HISTORY" })}
              onClick={() => setSelectedTab("HISTORY")}
            >
              <span>HISTORY</span>
            </div>
          </div>
          {isKeepingBottle() &&
            (status === FetchStatus.LOADING ? (
              <Loader />
            ) : (
              item.map((item, index) => (
                <div
                  className="card"
                  key={index}
                  onClick={() =>
                    navigate(`/keeping-bottle/${item.id}`, {
                      state: { data: item },
                    })
                  }
                >
                  <div className="detail">
                    <div className="title-wrap">
                      <span className="title">{item.name}</span>
                      <span className="keeping-date">
                        expired At{" "}
                        {moment(item.keeping_expired_at).format("DD MMM yyyy")}
                      </span>
                    </div>
                    <div className="date">
                      <p>{moment(item.keeping_at).format("DD MMM yyyy")}</p>
                      <AiOutlineRight />
                    </div>
                  </div>
                </div>
              ))
            ))}

          {isHistory() &&
            (status === FetchStatus.LOADING ? (
              <Loader />
            ) : (
              expiredData.map((item, index) => (
                <div
                  className="card"
                  key={index}
                  onClick={() =>
                    navigate(`/keeping-bottle/${item.id}`, {
                      state: { data: item },
                    })
                  }
                >
                  <div className="detail">
                    <div className="title-wrap">
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <span className="title">{item.name}</span>
                        {!item.pickup_date ? (
                          moment(item.keeping_expired_at).isAfter(moment()) ? (
                            ""
                          ) : (
                            <span className="expired-title">expired</span>
                          )
                        ) : (
                          <span className="picked-title">picked</span>
                        )}
                      </div>
                      <span className="keeping-date">
                        expired At{" "}
                        {moment(item.keeping_expired_at).format("DD MMM yyyy")}
                      </span>
                    </div>
                    <div className="date">
                      <p>{moment(item.keeping_at).format("DD MMM yyyy")}</p>
                      <AiOutlineRight />
                    </div>
                  </div>
                </div>
              ))
            ))}
        </div>
      </div>
    </>
  );
};
