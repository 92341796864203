import { FC, InputHTMLAttributes } from 'react'

import './styles.scss'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string
}

export const InputText: FC<Props> = ({ name, ...props }) => {
  return (
    <div className="input-wrapper">
      <input id={name} type="text" {...props} />
    </div>
  )
}