import { FC } from 'react'

import './styles.scss'

export const Loader: FC = () => {
  return (
    <div className="loader-container">
      <img
        className="loader"
        src={require('assets/images/loader.svg').default}
      />
    </div>
  )
}
