import { api } from 'utils'
import { CLIENT_CODE } from 'constant'

import {
  GetAllEventResponse,
  GetAllEventParams,
  EventService,
  GetEventDetailResponse
} from '.'

const getAllEvent = async (
  params: GetAllEventParams,
): Promise<GetAllEventResponse> => {
  try {
    const res = await api.get<GetAllEventResponse>('/event', { params })
    return res.data
  } catch (e) {
    throw e
  }
}

const getEventDetail = async (
  id: string,
): Promise<GetEventDetailResponse> => {
  try {
    const res = await api.get<GetEventDetailResponse>(`/event/${id}?client_code=${CLIENT_CODE}`)
    return res.data
  } catch (e) {
    throw e
  }
}

export const eventService: EventService = {
  getAllEvent,
  getEventDetail
}
