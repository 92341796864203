import { FC, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'

import { PromoItem, useStoreActions, useStoreState } from 'stores'

import { Topbar } from 'components'

import './styles.scss'
import { isEmpty } from 'utils'

export const PromotionDetail: FC = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const { fetchPromoDetail } = useStoreActions(action => action.promo)
  const { promoDetail } = useStoreState(state => state.promo)

  const [item, setItem] = useState<PromoItem>(promoDetail)

  let { id } = useParams()
  const data = location?.state?.data as PromoItem

  useEffect(() => {
    if (id) fetchPromoDetail(id)
  }, [fetchPromoDetail, id])

  useEffect(() => {
    setItem(data || promoDetail)
  }, [promoDetail, data])

  return (
    <div className="promotion-detail-container">
      {!isEmpty(item) &&
        <>
          <Topbar
            title="Promotion"
            onBack={() => navigate(-1)}
          />
          <div className="content">
            <img className="image" src={item.photos[0]} alt="promo-banner" />
            <div className="detail">
              <div className="title">{item.name}</div>
              <div className="date">
                <img
                  className="icon"
                  src={require('assets/icons/date.png')}
                  alt="icon"
                />
                <span>{moment(item.period.start).format('DD MMM YYYY')} - {moment(item.period.end).format('DD MMM YYYY')}</span>
              </div>
              <div
                className="description"
                dangerouslySetInnerHTML={{ __html: item.description }}
              />
            </div>
          </div>
        </>
      }
    </div>
  )
}
