import { Alert, Button, Input } from "components";
import { FC, useState } from "react";
import { isEmpty } from "utils";
import "./styles.scss";
import * as yup from "yup";
import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { useRegister } from "./hooks";

export const Register: FC = () => {
  const [error, setError] = useState<string>("");
  const [success, setSuccess] = useState<string>("");
  const location = useLocation();

  const navigate = useNavigate();

  const data = location?.state;
  const [isLoading, submit] = useRegister(setError, setSuccess);

  const RegisterSchema = yup.object().shape({
    mobil_phone: yup.number().required("Required"),
    password: yup
      .string()
      .required("Required")
      .min(8, "At Leasst 8 characters")
      .max(50, "Too Long!"),
    confirm_password: yup
      .string()
      .oneOf([yup.ref("password")], "Passwords must match"),
  });

  const formik = useFormik({
    initialValues: {
      mobile_phone: data?.mobile_phone,
      password: "",
      confirm_password: "",
      phone_verification_code: "",
    },
    validationSchema: RegisterSchema,
    onSubmit: (values) => console.log(values),
  });

  formik.handleSubmit = (e) => {
    e?.preventDefault();
    submit(formik.values);
  };

  console.log(Object.keys(formik.errors).length === 0);

  return (
    <div className="login-container">
      <div className="content">
        <div className="title">Set Up Password</div>
        <div className="subtitle">Setup your password account</div>

        <form className="form" onSubmit={formik.handleSubmit}>
          <Input.Text
            name="phone_verification_code"
            placeholder="Enter your Verification Code"
            onChange={formik.handleChange}
            value={formik.values.phone_verification_code}
          />
          <Input.Text
            name="mobile_phone"
            placeholder="Enter your phone number"
            onChange={formik.handleChange}
            value={formik.values.mobile_phone}
            readOnly
          />
          <Input.Password
            name="password"
            placeholder="Enter your password"
            onChange={formik.handleChange}
            value={formik.values.password}
          />
          {formik.errors.password && (
            <div className="error-message">{formik.errors.password}</div>
          )}
          <Input.Password
            name="confirm_password"
            placeholder="Confirm your password"
            onChange={formik.handleChange}
            value={formik.values.confirm_password}
          />
          {formik.errors.confirm_password && (
            <div className="error-message">
              {formik.errors.confirm_password}
            </div>
          )}
          <Button type="submit" label="Register" isLoading={isLoading} />
        </form>
      </div>
      {!isEmpty(error) && (
        <Alert title="Register Failed" message={error} onClick={() => {}} />
      )}
      {!isEmpty(success) && (
        <Alert
          title="Register Success"
          message={success}
          onClick={() => navigate("/login")}
        />
      )}
    </div>
  );
};
