import { FC, useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

import { useStoreActions, useStoreState } from 'stores'
import { FetchStatus } from 'types'

import { Loader, Topbar } from 'components'

import './styles.scss'

export const Notification: FC = () => {
  const navigate = useNavigate()

  const { fetchNotification } = useStoreActions(action => action.notification)
  const { data, status } = useStoreState(state => state.notification)

  const getNotification = useCallback(() => {
    fetchNotification({
      page: 1,
      limit: 20,
      type: 'all'
    })
  }, [fetchNotification])

  useEffect(() => {
    getNotification()
  }, [])
  return (
    <div className="notification-container">
      <Topbar
        title="Notification"
        onBack={() => navigate(-1)}
      />
      {status === FetchStatus.LOADING ?
        <Loader />
        :
        <div className="content">
          {data.map((item, index) =>
            <div
              key={index}
              className="card"
              style={{ backgroundColor: item.is_read ? '#fff' : '#f5f5f5' }}
              onClick={() => navigate(`/notification/${item.id}`, { state: { data: item } })}
            >
              <div className="top">
                <div className="title">{item.title || '-no title-'}</div>
                <div className="date">{moment(item.date).calendar()}</div>
              </div>
              <div className="detail">
                <img
                  className="icon"
                  src={require(`assets/icons/${item.is_read ? 'mail' : 'notification'}.png`)}
                />
                <div className="message-content">
                  <div className="body">{item.body || '-no body-'}</div>
                  <img
                    className="thumbnail"
                    src={item.image}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      }
    </div>
  )
}
