import { useEffect, useRef } from "react";
import bwipjs from "bwip-js";

interface BarcodeProps {
  data: string;
}

export const Barcode: React.FC<BarcodeProps> = ({ data }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    try {
      if (canvasRef.current) {
        //@ts-ignore
        bwipjs.toCanvas(canvasRef.current, {
          bcid: "qrcode",
          text: data,
          includetext: true,
          height: 40,
          width: 40,
        });
      }
    } catch (e) {
      console.log(e);
    }
  }, []);

  return <canvas ref={canvasRef} />;
};
