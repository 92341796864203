import { useState } from 'react'

import { api, isEmpty } from 'utils'
import { useStoreActions } from 'stores'

export interface EditProfileDto {
  profile_picture: string
  nik: string
  name: string
  mobile_phone: string
  email: string
  gender: string
  religion: string
  marital_status: string
  birth_date: string
  province: string
  city: string
  district: string
  address: string
  village: string
}

export interface UseEditProfile {
  isLoading: boolean
  error: string
  success: boolean
  submit: (dto: EditProfileDto) => Promise<void>
  setError: (value: string) => void
}

export const useEditProfile = (): UseEditProfile => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState(false)
  const { refreshProfile } = useStoreActions((action) => action.profile)

  const submit = async (dto: EditProfileDto): Promise<void> => {
    console.log('DTO', dto)
    try {
      setIsLoading(true)
      const formData = new FormData()
      formData.append('name', dto.name)

      if (!isEmpty(dto.email)) {
        formData.append('email', dto.email)
      }

      // if (dto.gender != '') {
      //   formData.append('gender', dto.gender)
      // }

      // if (dto.religion != '') {
      //   formData.append('religion', dto.religion)
      // }

      // if (dto.marital_status != '') {
      //   formData.append('marital_status', dto.marital_status)
      // }

      // if (dto.province != '') {
      //   formData.append('province', dto.province)
      // }

      // if (dto.city != '') {
      //   formData.append('city', dto.city)
      // }

      // if (dto.district != '') {
      //   formData.append('district', dto.district)
      // }

      // if (dto.village != '') {
      //   formData.append('village', dto.village)
      // }

      if (dto.address != '') {
        formData.append('address', dto.address)
      }

      // if (dto.mobile_phone != '') {
      //   formData.append('mobile_phone', `62${dto.mobile_phone}`)
      // }

      // if (!isEmpty(dto.birth_date) && dto.birth_date !== 'Invalid date') {
      //   formData.append('birth_date', dto.birth_date)
      // }

      // if (!isEmpty(dto.profile_picture)) {
      //   formData.append('profile_picture', {
      //     uri: dto.profile_picture,
      //     type: 'image/jpeg',
      //     name: `${new Date().getTime()}.jpg`,
      //   })
      // }

      await api.post('/customer',
        formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        transformRequest: formData => formData,
      })
      refreshProfile()
      setIsLoading(false)
      setSuccess(true)
    } catch (e: any) {
      console.log('ERROR', e)
      setError(e?.response?.data?.message || 'Mohon cek kembali jaringan Anda')
      setIsLoading(false)
      setSuccess(false)
    }
  }

  return {
    isLoading,
    error,
    success,
    setError,
    submit,
  }
}
