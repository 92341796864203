import {
  IKeepingBottle,
  GetAllKeepingBottleResponse,
  FetchKeepingBottleParams,
  GetKeepingBottleDetail,
} from ".";
import { PaginationData } from "types";
import { BASE_URL_KEEPING_BOTTLE } from "constant";
import moment from "moment-timezone";
import { Profile } from "stores/profile";
import { generateTokenBottle, getTokenBottle } from "utils";
import axios from "axios";

const fetchKeepingBottle = async (
  params: FetchKeepingBottleParams
): Promise<PaginationData<IKeepingBottle>> => {
  const access_token = await getTokenBottle();
  try {
    const response = await axios.get<PaginationData<IKeepingBottle>>(
      `${BASE_URL_KEEPING_BOTTLE}/v1/keeping`,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
          "X-Timestamp": moment().tz(moment.tz.guess()).toISOString(true),
        },
        params: {
          page: params.page,
          limit: params.limit,
          sort_by: params.sort_by,
        },
      }
    );

    const { data } = response;

    return data;
  } catch (err) {
    throw err;
  }
};

const getBottleDetail = async (id: string): Promise<GetKeepingBottleDetail> => {
  try {
    const access_token = await getTokenBottle();
    const response = await axios.get<GetKeepingBottleDetail>(
      `${BASE_URL_KEEPING_BOTTLE}/v1/keeping/${id}`,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
          "X-Timestamp": moment().tz(moment.tz.guess()).toISOString(true),
        },
      }
    );

    return response.data;
  } catch (err) {
    throw err;
  }
};

export const keepingBottleService = {
  fetchKeepingBottle,
  getBottleDetail,
};
