import { memo } from 'react'
import SwipeableBottomSheet from '@sergeymyssak/swipeable-bottom-sheet'
import '@sergeymyssak/swipeable-bottom-sheet/lib/min.css'

export const BottomSheet = ({
  isOpen,
  onChange,
  children,
  disableSwipe = false
}) => (
  <SwipeableBottomSheet
    isOpen={isOpen}
    onChange={onChange}
    swipeableViewsProps={{ disabled: disableSwipe }}
    containerClassName="bottom-sheet"
    bodyClassName="bottom-sheet-content-container"
  >
    {children}
  </SwipeableBottomSheet>
)

export default memo(BottomSheet)