import { action, thunk, computed } from 'easy-peasy'

import { FetchStatus } from 'types'
import { isEmpty } from 'utils'

import { NotificationItem, NotificationModel, historyService } from '.'

export const notification: NotificationModel = {
  isEmpty: computed(
    (state) => state.status === FetchStatus.LOADED && isEmpty(state.data)
  ),
  paginator: {
    current_page: 1,
    next_page: 1,
    per_page: 10,
    previous_page: 1,
    total_items: 0,
    total_pages: 1
  },
  data: [],
  detail: {} as NotificationItem,
  status: FetchStatus.LOADING,
  error: {},

  // Actions
  setPaginator: action((state, payload) => {
    state.paginator = payload
  }),
  setData: action((state, payload) => {
    state.data = payload
  }),
  addData: action((state, payload) => {
    state.data = [...state.data, ...payload]
  }),
  setDetail: action((state, payload) => {
    state.detail = payload
  }),
  setStatus: action((state, payload) => {
    state.status = payload
  }),
  setError: action((state, payload) => {
    state.error = payload
  }),

  // Thunks
  refreshNotification: thunk(async (action) => {
    try {
      action.setStatus(FetchStatus.REFRESHING)
      const res = await historyService.fetchNotification({
        page: 1,
        limit: 15,
        type: 'all'
      })
      action.setPaginator(res.paginator)
      action.setData(res.data)
    } catch (e) {
      action.setStatus(FetchStatus.REFRESH_ERROR)
    } finally {
      action.setStatus(FetchStatus.LOADED)
    }
  }),

  fetchNotification: thunk(async (action, payload) => {
    try {
      action.setStatus(FetchStatus.LOADING)
      const res = await historyService.fetchNotification(payload)
      action.setData(res.data)
      action.setPaginator(res.paginator)
      action.setStatus(FetchStatus.LOADED)
      action.setError({})
    } catch (e: any) {
      action.setError(e)
      action.setStatus(FetchStatus.ERROR)
    }
  }),

  fetchMoreNotification: thunk(async (action, payload) => {
    try {
      action.setStatus(FetchStatus.FETCHING_MORE)
      const res = await historyService.fetchNotification(payload)
      action.setPaginator(res.paginator)
      action.addData(res.data)
      action.setStatus(FetchStatus.LOADED)
      action.setError({})
    } catch (e) {
      action.setStatus(FetchStatus.FETCHING_MORE_ERROR)
    } finally {
      action.setStatus(FetchStatus.LOADED)
    }
  }),

  fetchNotificationDetail: thunk(async (action, payload) => {
    try {
      action.setStatus(FetchStatus.LOADING)
      const res = await historyService.fetchNotificationDetail(payload)
      action.setDetail(res.data)
      action.setStatus(FetchStatus.LOADED)
      action.setError({})
    } catch (e: any) {
      action.setError(e)
      action.setStatus(FetchStatus.ERROR)
    }
  }),
}
