import { action, thunk, computed } from 'easy-peasy'

import { FetchStatus, FormStatus } from 'types'
import { isEmpty } from 'utils'

import { RedeemVoucherModel, fetchRedeemVouchersUsed, fetchRedeemVouchersUnused, validateRedeemVoucher } from '.'

export const redeemVoucherModel: RedeemVoucherModel = {
  isEmptyUsed: computed(
    (state) => isEmpty(state.used) && state.statusUsed === FetchStatus.LOADED,
  ),
  isEmptyUnused: computed(
    (state) => isEmpty(state.unused) && state.statusUnused === FetchStatus.LOADED,
  ),
  paginatorUsed: {
    current_page: 1,
    next_page: 1,
    per_page: 15,
    previous_page: 1,
    total_items: 0,
    total_pages: 1,
  },
  paginatorUnused: {
    current_page: 1,
    next_page: 1,
    per_page: 15,
    previous_page: 1,
    total_items: 0,
    total_pages: 1,
  },
  used: [],
  unused: [],
  statusUsed: FetchStatus.LOADING,
  statusUnused: FetchStatus.LOADING,
  error: '',
  validateRedeem: null,
  statusValidate: FormStatus.IDLE,

  // Actions
  setPaginatorUsed: action((state, payload) => {
    state.paginatorUsed = payload
  }),
  setPaginatorUnused: action((state, payload) => {
    state.paginatorUnused = payload
  }),
  setDataUsed: action((state, payload) => {
    state.used = payload
  }),
  setDataUnused: action((state, payload) => {
    state.unused = payload
  }),

  addDataUsed: action((state, payload) => {
    state.used = [...state.used, ...payload]
  }),
  addDataUnused: action((state, payload) => {
    state.unused = [...state.unused, ...payload]
  }),
  setStatusUsed: action((state, payload) => {
    state.statusUsed = payload
  }),
  setStatusUnused: action((state, payload) => {
    state.statusUnused = payload
  }),
  setError: action((state, payload) => {
    state.error = payload
  }),
  setValidateRedeem: action((state, payload) => {
    state.validateRedeem = payload
  }),
  setStatusValidate: action((state, payload) => {
    state.statusValidate = payload
  }),

  // Thunks
  fetchRedeemVouchersUsed: thunk(async (action, payload) => {
    try {
      action.setStatusUsed(FetchStatus.LOADING)
      const res = await fetchRedeemVouchersUsed(payload)
      action.setPaginatorUsed(res.paginator)
      action.setDataUsed(res.data)
      action.setStatusUsed(FetchStatus.LOADED)
      action.setError('')
    } catch (e: any) {
      action.setError(e?.response?.data?.message || 'Network Error')
      action.setStatusUsed(FetchStatus.ERROR)
    }
  }),
  fetchMoreUsed: thunk(async (action, payload) => {
    try {
      action.setStatusUsed(FetchStatus.FETCHING_MORE)
      const res = await fetchRedeemVouchersUsed(payload)
      action.setPaginatorUsed(res.paginator)
      action.addDataUsed(res.data)
      action.setError('')
    } catch (e: any) {
      action.setError(e?.response?.data?.message || 'Network Error')
      action.setStatusUsed(FetchStatus.FETCHING_MORE_ERROR)
    } finally {
      action.setStatusUsed(FetchStatus.LOADED)
    }
  }),
  fetchRedeemVouchersUnused: thunk(async (action, payload) => {
    try {
      action.setStatusUnused(FetchStatus.LOADING)
      const res = await fetchRedeemVouchersUnused(payload)
      action.setPaginatorUnused(res.paginator)
      action.setDataUnused(res.data)
      action.setStatusUnused(FetchStatus.LOADED)
      action.setError('')
    } catch (e: any) {
      action.setError(e?.response?.data?.message || 'Network Error')
      action.setStatusUnused(FetchStatus.ERROR)
    }
  }),
  fetchMoreUnused: thunk(async (action, payload) => {
    try {
      action.setStatusUnused(FetchStatus.FETCHING_MORE)
      const res = await fetchRedeemVouchersUnused(payload)
      action.setPaginatorUnused(res.paginator)
      action.addDataUnused(res.data)
      action.setError('')
    } catch (e: any) {
      action.setError(e?.response?.data?.message || 'Network Error')
      action.setStatusUnused(FetchStatus.FETCHING_MORE_ERROR)
    } finally {
      action.setStatusUnused(FetchStatus.LOADED)
    }
  }),
  validateRedeemVoucher: thunk(async (action, payload) => {
    try {
      action.setStatusValidate(FormStatus.LOADING)
      const res = await validateRedeemVoucher(payload)
      action.setValidateRedeem(res.data)
      action.setError('')
      action.setStatusValidate(FormStatus.SUCCESS)
    } catch (e: any) {
      action.setError(e?.response?.data?.message || 'Network Error')
      action.setStatusUnused(FetchStatus.ERROR)
    } finally {
      action.setStatusValidate(FormStatus.IDLE)
    }
  })
}
