import axios from 'axios'

import { BASE_URL } from 'constant'
import store from 'stores'

export const api = axios.create({
  baseURL: BASE_URL
})

export const setDefaultAuthJwt = (jwt: string): void => {
  api.defaults.headers.common['Authorization'] = 'Bearer ' + jwt
  api.interceptors.response.use(response => {
    return response
  }, error => {
    if (error.response.status === 401)
      store.dispatch.auth.loggedOut(() => console.log('LOGGED OUT: Unauthenticated'))
    return Promise.reject(error)
  })
}
