import { FC, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

import { useStoreActions, useStoreState } from 'stores'
import { CLIENT_CODE } from 'constant'

import { Alert, Button, Input, Loader, Topbar } from 'components'

import './styles.scss'
import { FetchStatus } from 'types'
import { number } from 'yup'
import { api, getAccessToken, isEmpty } from 'utils'

type ConvertInquiry = {
  ld_conversion_limit: number
  lucky_draw: number
  redeem_point: number
}

export const ConvertPoint: FC = () => {
  const navigate = useNavigate()

  const [point, setPoint] = useState<number>(1)
  const [luckyNumber, setLuckyNumber] = useState<number>(1)
  const [convertInquiry, setConvertInquiry] = useState<ConvertInquiry>()
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')

  useEffect(() => {
    setLuckyNumber(point / (convertInquiry?.redeem_point || 1))
  }, [point])

  const getConvertInquiry = useCallback(async () => {
    try {
      let URL = `convert-inquiry?code_client=${CLIENT_CODE}`
      const res = await api.get(URL)
      const { data } = res
      if (data.status_code === 200) {
        setConvertInquiry(data.data)
      }
    } catch (e) {
      throw e
    }
  }, [])

  useEffect(() => {
    getConvertInquiry()
  }, [])

  const convert = async () => {
    try {
      const accessToken = await getAccessToken()
      setLoading(true)
      const body = {
        code_client: CLIENT_CODE,
        access_token: accessToken,
        option: 'rd_to_ld',
        lucky_draw_point_amount: luckyNumber
      }

      const URL = 'convert-point'

      const res = await api.post(URL, body)
      const { data } = res
      setLoading(false)
      setError(data?.message)
    } catch (e: any) {
      console.log('ERROR', e?.response?.data?.message)
      setLoading(false)
      setError(e?.response?.data?.message)
    }
  }

  return (
    <div className="convert-point-container">
      <Topbar
        title="Convert Point"
        onBack={() => navigate(-1)}
      />
      {/* {status === FetchStatus.LOADING ?
        <Loader />
        :
        <div className="content">
          {events.map((item, index) =>
            <div className="card" key={index}>
              <img className="image" src={item.photos[0]} alt="promo-banner" />
              <div className="detail">
                <div className="title">{item.name}</div>
              </div>
            </div>
          )}
        </div>
      } */}
      <div className="content">
        <div
          style={{
            fontSize: '.8rem'
          }}
        >
          Convert your rewards easily with one click
        </div>
        <div
          style={{
            fontWeight: 600,
            marginTop: '1rem'
          }}
        >
          Conversion Rate
        </div>
        <div
          style={{
            fontSize: '.8rem',
            fontWeight: 500,
            marginTop: '.3rem'
          }}
        >
          {convertInquiry?.redeem_point || 1} Pt = {convertInquiry?.lucky_draw || 1} Draw
        </div>
        <div
          className="convert-card"
        >
          <div className="top">
            <div className="name">Convert Point</div>
          </div>
          <div className="detail">
            <div className="card">
              <div
                style={{
                  fontSize: '.8rem'
                }}
              >
                From <span style={{ fontWeight: 600 }}>Redeem Point</span>
              </div>
              <Input
                name="redeem_point"
                value={point}
                onChange={e => setPoint(Number(e.target.value.replace(/[^0-9]/g, '')))}
              />
              <div
                style={{
                  fontSize: '.8rem'
                }}
              >
                To <span style={{ fontWeight: 600 }}>Lucky Number</span>
              </div>
              <Input
                name="redeem_point"
                value={luckyNumber}
                disabled
              />
            </div>
          </div>
        </div>
        <div
          style={{
            marginTop: '1rem'
          }}
        >
          <Button
            label="Convert"
            onClick={convert}
          />
        </div>
      </div>
      {!isEmpty(error) &&
        <Alert
          title="Convert Point"
          message={error}
          onClick={() => setError('')}
        />
      }
    </div>
  )
}
