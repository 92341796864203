import { Data, PaginationData } from 'types'
import { api } from 'utils'
import { CLIENT_CODE } from 'constant'

import { FetchAllProductRedeemParam, ProductRedeem } from '.'

const fetchProductRedeem = async (
  params: FetchAllProductRedeemParam,
): Promise<PaginationData<ProductRedeem>> => {
  try {
    const res = await api.get('/redeem/product', {
      params: { ...params, client_code: CLIENT_CODE }
    })
    const { data } = res
    return data
  } catch (e) {
    throw e
  }
}

const fetchProductRedeemDetail = async (
  id: string,
): Promise<Data<ProductRedeem>> => {
  try {
    const res = await api.get(`/redeem/product/${id}?client_code=${CLIENT_CODE}`)
    const { data } = res
    return data
  } catch (e) {
    throw e
  }
}

export const productRedeemService = {
  fetchProductRedeem,
  fetchProductRedeemDetail
}