import { FC, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import moment from 'moment'

import { useStoreActions, useStoreState } from 'stores'
import { FetchStatus } from 'types'

import { Loader, Topbar } from 'components'

import './styles.scss'

export const History: FC = () => {
  const navigate = useNavigate()

  const { fetchHistory } = useStoreActions(action => action.history)
  const { data, status } = useStoreState(state => state.history)
  const [type, setType] = useState<string>('')

  const getHistory = useCallback((type: string) => {
    fetchHistory({
      page: 1,
      limit: 20,
      type
    })
  }, [fetchHistory])

  useEffect(() => {
    getHistory(type)
  }, [getHistory, type])
  return (
    <div className="history-container">
      <Topbar
        title="History"
        onBack={() => navigate(-1)}
      />
      <div className="content">
        <div className="category">
          <div
            className={classNames({ active: type === '' })}
            onClick={() => setType('')}
          >
            All
          </div>
          <div
            className={classNames({ active: type === 'earning' })}
            onClick={() => setType('earning')}
          >
            Approved
          </div>
          <div
            className={classNames({ active: type === 'redeem' })}
            onClick={() => setType('redeem')}
          >
            Redeem
          </div>
          <div
            className={classNames({ active: type === 'convert-point' })}
            onClick={() => setType('convert-point')}
          >
            Convert Point
          </div>
        </div>
      </div>
      {status === FetchStatus.LOADING ?
        <Loader /> :
        <div className="content">
          {data.map((item, index) =>
            <div className="card" key={index}>
              <div className="top">
                <div className="type">{item.type}</div>
                <div className="point">
                  <span>{item.point.redeem} Points</span>

                </div>
              </div>
              <div className="detail">
                <div className="card">
                  <img
                    className="image"
                    src={require('assets/images/logo-loyalid-blue.svg').default}
                    alt="logo"
                  />
                  <div>
                    <div className="receipt-no">{item.receipt_no}</div>
                    <div className="date">{moment(item.date).fromNow()}</div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      }
    </div>
  )
}
